<template>
  <div class="typeArea content">
    <div class="title">
      <div class="title-header">
        关于我们
      </div>
      <div class="title-content">
        科研人（www.keyanpro.com）是一家面向全国科研院所、高校科研人员的大数据服务平台，致力于技术创新服务科研工作。成立以来，科研人不断革新技术，持续建设和完善科研领域大数据，为广大科研人员提供了精准、高效而可靠的信息服务。
      </div>
    </div>
    <div class="content-item">
      <div class="item-header">
        我们解决的问题
      </div>
      <div class="item-conten">
        <!-- <div class="content-header">
          让天下没有难做的科研
        </div> -->
        <div class="content-main">
          科研项目机会的挖掘与对历史数据的分析一直是广大科研人员日常工作的重要内容，然而常规的手段和工具不仅耗时耗力，且常常达不到理想效果。科研人创新性地使用自然语言处理和大数据分析技术，能够切实有效地帮助院所和高校科研人员发现科研项目机会，提高科研决策效率。
        </div>
        <div class="content-main">
          <span>获取项目机会</span> ：每日跟踪科技部、工信部、教育部等各大部委官网、各省市有关管理部门以及主要的政府采购平台，形成海量的科研课题和政府采购信息，按照学科专业和行业领域进行划分，基于个性化订阅，能够第一时间为广大科研人员推送符合个人需求的项目机会信息。
        </div>
        <div class="content-main">
          <span>发现科研前沿</span>：系统集成了中国、美国、日本、韩国、俄罗斯等国家的主要科研项目信息，形成了规模接近1000万条的科研立项大数据库，通过大数据挖掘手段，能够为广大科研人员提供洞察各科研领域最新的发展方向。同时也能够支撑用户挖掘各基金/科研项目的主要特点。
        </div>
        <div class="content-main">
          <span>促进科研交流</span>：通过微信公众号和专业微信群，科研人为广大科研从业者提供了一个交流平台。我们坚信，每一次开放、积极、健康的对话都能够为科研工作带来新的机会或灵感，我们也努力促进科研院所与高校的两类科研群体的交流与碰撞，让每一位科研人员发现更多科研机会。
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="item-header">
        我们的服务理念
      </div>
      <div class="item-conten">
        <div class="content-main">
          科研人凭借自身在内容专业性、技术先进性及服务专业化等方面的优势，为广大科研人员提供创新、专业且具有实用价值的大数据与信息服务。
        </div>
        <div class="content-main">
          <span>以人为本</span>：每一项细节都充分考虑用户的需求，都在为用户节省时间成本上下足功夫，切实能够为用户带来可靠有用的信息。
        </div>
        <div class="content-main">
          <span>技术驱动</span>：通过个性化推荐、自然语言处理、机器翻译等人工智能技术，为用户提供高效的交互方式。
        </div>
        <div class="content-main">
          <span>专业服务</span>：专业的线上产品与科研出身的服务团队相结合，带来不一样的服务体验和效果。
        </div>
      </div>
    </div>
    <div class="contact-us">
      <div class="us-left">
        <div class="left-header">
          联系我们
        </div>
        <div class="left-content">
          <div class="content-item">
            <div class="item-describe">
              邮箱：
            </div>
            <div class="item-details">
              ser@keyanpro.com
            </div>
          </div>
          <div class="content-item">
            <div class="item-describe">
              微信公众号：
            </div>
            <div class="item-details">
              keyanpro2020
            </div>
          </div>
          <div class="content-item">
            <div class="item-describe">
              微信客服：
            </div>
            <div class="item-details">
              keyanpro
            </div>
          </div>
          <div class="content-item">
            <div class="item-describe">
              或扫描右侧相应二维码：
            </div>
          </div>
        </div>
      </div>
      <div class="us-right">
        <div class="right-item">
          <div class="item-img">
            <img
              src="../../../assets/image/关于我们_slices/小程序.png"
              alt=""
            >
          </div>
          <div class="item-text">小程序版</div>
        </div>
        <div class="right-item">
          <div class="item-img">
            <img
              src="../../../assets/image/关于我们_slices/公众号.png"
              alt=""
            >
          </div>
          <div class="item-text">微信公众号</div>
        </div>
        <div class="right-item">
          <div class="item-img">
            <img
              src="../../../assets/image/关于我们_slices/微信客服.png"
              alt=""
            >
          </div>
          <div class="item-text">微信客服</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    this.navxuanzhong()
  },
  methods: {
     navxuanzhong(){
      localStorage.setItem("navActive", 0);
      this.$store.commit("setnavActive", 0);
    },
  }
}
</script>

<style lang="scss" scoped>
// 版心
.typeArea {
  width: 740px;
  margin: 0 auto;
}
.content {
  padding-top: 80px;
  padding-bottom: 232px;
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-header {
      font-size: 35px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #5e068c;
      margin-bottom: 70px;
    }
    .title-content {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
      color: #333333;
      margin-bottom: 70px;
    }
  }
  .content-item {
    .item-header {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #5e068c;
      margin-bottom: 40px;
    }
    .item-conten {
      .content-header {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 549px;
        color: #333333;
        margin-bottom: 17px;
      }
      .content-main {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400px;
        color: #333333;
        line-height: 27px;
        margin-bottom: 42px;
        span {
          color: #5e068c;
        }
      }
    }
  }
  .contact-us {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .us-left {
      .left-header {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #5e068c;
        margin-bottom: 40px;
      }
      .left-content {
        .content-item {
          display: flex;
          align-items: center;
          .item-describe {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400px;
            color: #333333;
            line-height: 30px;
          }
          .item-details {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400px;
            color: #5e068c;
            line-height: 30px;
          }
        }
      }
    }
    .us-right {
      display: flex;
      justify-content: right;
      align-items: center;
      .right-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 24px;
        .item-img {
          img {
            width: 130px;
            height: 130px;
          }
        }
        .item-text {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
